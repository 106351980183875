import axios from "axios"

import { API_BASE_URL, API_ENDPOINTS, API_BASE_URL_FULL } from "./apiConfig"

// Create an instance of Axios with custom configurations
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
})

const apiClientFull = axios.create({
  baseURL: API_BASE_URL_FULL,
  timeout: 10000,
})

// Add a response interceptor for error handling or logging
apiClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export { apiClient, API_ENDPOINTS, apiClientFull }
