import { useState, useEffect } from "react"

function useIsMobileDevice() {
  const [isMobileDevice, setisMobileDevice] = useState(false)
  const mobileBreakpoint = 767

  function isMobile() {
    return window?.innerWidth <= mobileBreakpoint
  }

  useEffect(() => {
    const handleResize = () => {
      setisMobileDevice(isMobile)
    }

    window?.addEventListener("resize", handleResize)

    return () => {
      window?.removeEventListener("resize", handleResize)
    }
  }, [])

  return isMobileDevice
}

export default useIsMobileDevice
