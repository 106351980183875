const API_BASE_URL = ""

const API_BASE_URL_FULL = "https://api.vantagecircle.co.in"

const API_ENDPOINTS = {
  AIRE_CALCULATION: "/api/v1/general/aire/calculation",
  PULSE_REGISTRATION: "/api/survey/addCompanyForPulse",
  GET_COUNTRIES: "/api/v1/countries/getAllCountries",
  RESEND_VERIFICATION_LINK_FOR_REGISTRATION:
    "/api/survey/resendVerificationEmailForPulse",
}

export { API_BASE_URL, API_ENDPOINTS, API_BASE_URL_FULL }
