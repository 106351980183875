import React, { useState, useEffect } from "react"
import { apiClient, API_ENDPOINTS } from "../api-config/apiClient"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import useIsMobileDevice from "../hooks/useIsMobileDevice"
import HubspotForm from "react-hubspot-form"

function PulseRegistration() {
  const MySwal = withReactContent(Swal)

  const [formVals, setFormVals] = useState({
    name: "",
    email: "",
    company_name: "",
    country: "",
    domain_name: "",
  })

  let userId = undefined

  const isMobileDevice = useIsMobileDevice()

  const [check, setChecked] = useState(false)

  const [allFilled, setAllFilled] = useState(false)

  const [sucess, setSucess] = useState(false)

  const [loading, setLoading] = useState(false)

  const [countries, setCountries] = useState(undefined)

  const containerStyle = {
    borderRadius: "var(--border-radius-radius-xl, 12px)",
    border: "3px solid rgba(255, 255, 255, 0.50)",
    background:
      "linear-gradient(86deg, rgba(255, 255, 255, 0.51) 11.14%, rgba(255, 255, 255, 0.65) 113.29%)",
    boxShadow: "0px 1.1966px 29.91505px 0px rgba(69, 42, 124, 0.10)",
    backdropFilter: "blur(35px)",
  }

  const pulseWrapperStyles = {
    backgroundImage:
      'url("https://res.cloudinary.com/vantagecircle/image/upload/v1696573141/pulse/girl-landingpage-pulse.png")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
  }

  useEffect(() => {
    async function fetchCountries() {
      try {
        setLoading(true)
        const response = await apiClient.get(API_ENDPOINTS.GET_COUNTRIES)
        const data = response.data.data
        setCountries(data)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchCountries()
  }, [])

  useEffect(() => {
    isAllFormValsFilled()
  }, [formVals, check])

  function handleInputChange(event) {
    const target = event.target
    const name = target.name
    const value = target.value

    setFormVals({
      ...formVals,
      [name]: value,
    })
  }

  function isAllFormValsFilled() {
    const valsArr = Object.values(formVals)

    const isEmpty = valsArr.some(e => e.trim().length === 0)
    if (!isEmpty && check) {
      setAllFilled(true)
    } else {
      setAllFilled(false)
    }
  }

  function handleCheck(e) {
    setChecked(e.target.checked)
  }

  async function sendData() {
    try {
      const response = await apiClient.post(
        API_ENDPOINTS.PULSE_REGISTRATION,
        formVals
      )
      return response
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.err,
      })
      return error.response
    }
  }

  async function handleResendVerification() {
    try {
      setLoading(true)

      const response = await apiClient.post(
        API_ENDPOINTS.RESEND_VERIFICATION_LINK_FOR_REGISTRATION,
        { user_id: userId }
      )

      if (response.status === 200) {
        setLoading(false)
      }
    } catch (error) {
      return error.response
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setLoading(true)
    if (!check) return
    sendData()
      .then(res => {
        userId = res?.data?.statuses?.user_id
        setLoading(false)
        if (res.status === 200) {
          setSucess(true)
        }
        if (res.status === 400) {
          console.log(res, "new err")
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.data.err,
          })
        }
      })
      .catch(err => {
        console.log(err, "error inisde 2nd catch")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function renderSucessSection() {
    return (
      <>
        <div className="col-span-1 text-center mt-5 w-100 order-1 md:order-2">
          <div className="flex justify-center">
            {" "}
            <svg
              width="63"
              height="63"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4"
                y="4"
                width="55"
                height="55"
                rx="27.5"
                fill="#D1FADF"
              />
              <rect
                x="4"
                y="4"
                width="55"
                height="55"
                rx="27.5"
                stroke="#ECFDF3"
                stroke-width="8"
              />
              <path
                d="M41.5 30.5818V31.5018C41.4988 33.6582 40.8005 35.7565 39.5093 37.4836C38.2182 39.2108 36.4033 40.4743 34.3354 41.0857C32.2674 41.6971 30.0573 41.6237 28.0345 40.8764C26.0117 40.1291 24.2847 38.7479 23.111 36.9389C21.9373 35.1299 21.3798 32.9899 21.5217 30.8381C21.6636 28.6864 22.4972 26.6381 23.8983 24.9989C25.2994 23.3596 27.1928 22.2172 29.2962 21.742C31.3996 21.2667 33.6003 21.4842 35.57 22.3618M41.5 23.5018L31.5 33.5118L28.5 30.5118"
                stroke="#039855"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          <p className="font-bold border-b pb-5 pt-2 md:w-2/3 mx-auto border-gray-light">
            Your account is created successfully.{" "}
          </p>
          <p className="max-w-xs mx-auto">
            We've sent you an email. You'll need to click the verification link
            inside it so that you can login.
          </p>
          <p className="md:mt-28">Didn't get an email?</p>
          {loading ? (
            <>
              <div className="text-center w-full">Loading... </div>
              <div className="loader2 mx-auto">
                <span></span>
              </div>
            </>
          ) : (
            <button
              onClick={handleResendVerification}
              className="border  border-gray-250 rounded w-100 px-10 md:px-20 py-2"
            >
              Resend verification email
            </button>
          )}

          <p className="font-bold mt-5">
            Already have an account?{" "}
            <a href="https://app.vantagecircle.com">Login Here</a>
          </p>
        </div>
      </>
    )
  }

  return (
    <div
      className="pulse-registration-wrapper pb-10"
      style={{
        background: "linear-gradient(90deg, #E9F2FC 9.11%, #EAE0ED 65.84%)",
      }}
    >
      <div
        className="container max-w-6xl px-2 md:px-5 pt-10 md:pt-10 "
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={!isMobileDevice ? containerStyle : null}
          className="group border border-transparent w-full rounded-lg shadow-lg  card-1 p-2 grid md:grid-cols-2 gap-5 md:gap-20 bg-indigo-500"
        >
          <div
            style={pulseWrapperStyles}
            className="col-span-1 md:p-5 p-4 bg-purple-800 rounded md:order-1 order-2"
          >
            {/* implementing Listern to your employee Start */}
            <a href="/">
              <img
                class="w-100 mx-auto vc-logo hidden md:block"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/v-pulse.webp"
                alt="Vantage Circle Logo"
                width="220"
                height="40"
              />
            </a>
            <div
              style={{ position: "relative" }}
              className="hero-img-pulse flex items-center justify-center my-5 md:mt-14"
            ></div>
            {/* implementing Listern to your employee Start */}
          </div>
          {sucess === true ? (
            renderSucessSection()
          ) : (
            <div className="md:col-span-1 order-1 md:order-2 mt-5 md:mt-0">
              <a className="md:hidden" href="/">
                <img
                  class="w-100 mx-auto vc-logo"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/05/v-pulse.webp"
                  alt="Vantage Circle Logo"
                  width="220"
                  height="40"
                />
              </a>
              <p className="mt-10 md:mt:0 text-center">
                Create your <span className="font-bold">FREE</span> account by
                registering with us.
              </p>
              <div
                id="subscribe-banner"
                class="subscribe_wrapper text-white pb-3"
              >
                <div class="flex justify-center items-center bottombannerPopUp p-4 pul-reg">
                  <HubspotForm
                    portalId="6153052"
                    formId="b5b8483d-d19e-41ff-b542-9b6d92b8fcca"
                    onSubmit={() => console.log("Submit!")}
                    onReady={form => console.log("Form ready!")}
                    loading={<div>Loading...</div>}
                  />
                </div>
              </div>
              <p className="mx-auto text-center">
                Already have an account?{" "}
                <span className="text-">
                  <a target="_blank" href="https://app.vantagecircle.com">
                    Login Here.
                  </a>
                </span>{" "}
              </p>
            </div>
          )}
        </div>
        <p className="py-5 text-base text-center md:text-left">
          © {new Date().getFullYear()}
          <a target="_blank" href="https://www.vantagecircle.com/">
            {" "}
            Vantage Circle
          </a>
          |{" "}
          <a
            target="_blank"
            href="https://www.vantagecircle.com/privacy-policy/"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            target="_blank"
            href="https://www.vantagecircle.com/terms-conditions/"
          >
            Terms and Conditions
          </a>{" "}
          |{" "}
          <a target="_blank" href="https://www.vantagecircle.com/contact-us/">
            Contact Us
          </a>
        </p>
      </div>
    </div>
  )
}

export default PulseRegistration
